import axios from 'axios';
import Cookie from 'js-cookie';

import { LOAD_COSTS_REQUEST, LOAD_COSTS_SUCCESS, LOAD_COSTS_FAILURE, SET_COSTS_REQUEST, SET_COSTS_SUCCESS, SET_COSTS_FAILURE, GET_COSTS_REQUEST, GET_COSTS_SUCCESS, GET_COSTS_FAILURE } from '../types/costsTypes';

export const getCosts = () => async dispatch => {
    try {
        dispatch({ type: GET_COSTS_REQUEST });

        const { data } = await axios.get('https://firedoor.napfis.co.uk/app_handler.php', { headers: { 'WG-Method': 'GET_COSTS', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: GET_COSTS_SUCCESS, payload: data });

        localStorage.setItem('costs', JSON.stringify(data));
    } catch (error) {
        dispatch({ type: GET_COSTS_FAILURE });
    }
}; 

export const setCosts = (formData) => async dispatch => {
    const fd = new FormData();

    let costs = {};

    for (const [key, value] of Object.entries(formData)) {
        fd.append(key, value);

        costs[key] = value;
    }

    localStorage.setItem('costs', JSON.stringify(costs));

    try {
        dispatch({ type: SET_COSTS_REQUEST });

        const { data } = await axios.post('https://firedoor.napfis.co.uk/app_handler.php', fd, { headers: { 'WG-Method': 'SAVE_COSTS', 'WG-Key': Cookie.get('accessToken') }});

        dispatch({ type: SET_COSTS_SUCCESS, payload: data });

        localStorage.setItem('costsCount', data.count);
    } catch (error) {
        dispatch({ type: SET_COSTS_FAILURE, payload: { data: formData, error: error.message }});
    }
};