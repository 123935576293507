import axios from 'axios';
import Cookie from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';

import {
    LOAD_SURVEY_REQUEST,
    LOAD_SURVEY_SUCCESS,
    LOAD_SURVEY_FAILURE,
    CREATE_SURVEY_REQUEST,
    CREATE_SURVEY_SUCCESS,
    CREATE_SURVEY_FAILURE,
    SAVE_DOOR_REQUEST,
    SAVE_DOOR_SUCCESS,
    SAVE_DOOR_FAILURE,
    LOAD_DOOR_REQUEST,
    LOAD_DOOR_SUCCESS,
    LOAD_DOOR_FAILURE,
    ADD_DOOR_REQUEST,
    ADD_DOOR_SUCCESS,
    ADD_DOOR_FAILURE,
    REMOVE_DOOR_REQUEST,
    REMOVE_DOOR_SUCCESS,
    REMOVE_DOOR_FAILURE,
    UPDATE_SURVEY_REQUEST,
    UPDATE_SURVEY_SUCCESS,
    UPDATE_SURVEY_FAILURE,
    CLEAR_DOOR_PROCEED,
    ASSIGN_PINS_REQUEST,
    ASSIGN_PINS_SUCCESS,
    ASSIGN_PINS_FAILURE,
    SET_ACTIVE_PLAN_REQUEST,
    SET_ACTIVE_PLAN_SUCCESS,
    SET_ACTIVE_PLAN_FAILURE,
    SAVE_DOOR_REF_REQUEST,
    SAVE_DOOR_REF_SUCCESS,
    SAVE_DOOR_REF_FAILURE,
    REMOVE_ALL_DOORS_REQUEST,
    REMOVE_ALL_DOORS_SUCCESS,
    REMOVE_ALL_DOORS_FAILURE,
    SET_PDF_SELECTOR_TRUE,
} from '../types/surveyTypes';
import { CLEAR_DOORS, LOAD_DOORS } from '../types/doorTypes';

export const loadSurvey = (surveyId) => (dispatch) => {
    try {
        if (!surveyId) {
            throw Error('No survey key');
        }

        dispatch({ type: LOAD_SURVEY_REQUEST });

        const savedSurvey = JSON.parse(localStorage.getItem('survey'));

        if (savedSurvey && savedSurvey[surveyId]) {
            dispatch({
                type: LOAD_SURVEY_SUCCESS,
                payload: { ...savedSurvey[surveyId] },
            });

            const doors = JSON.parse(localStorage.getItem('doors'));

            if (doors) {
                const surveyDoors = doors[surveyId];

                dispatch({ type: LOAD_DOORS, payload: surveyDoors });
            }

            localStorage.setItem('activeSurvey', surveyId);
        } else {
            throw Error('Survey not found');
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: LOAD_SURVEY_FAILURE });
    }
};

export const createSurvey =
    (
        userId,
        clientName,
        clientTelephone,
        clientEmail,
        surveyAddress,
        comments,
        includeCosts
    ) =>
    async (dispatch, getState) => {
        const formPayload = {
            inspection_client_name: clientName,
            inspection_client_telephone: clientTelephone,
            inspection_client_email: clientEmail,
            inspection_survey_address: surveyAddress,
            inspection_comments: comments,
            inspection_include_costs: includeCosts,
        };

        const fd = new FormData();

        fd.append('inspection_client_name', clientName);
        fd.append('inspection_client_telephone', clientTelephone);
        fd.append('inspection_client_email', clientEmail);
        fd.append('inspection_survey_address', surveyAddress);
        fd.append('inspection_comments', comments);
        fd.append('inspection_include_costs', includeCosts);

        const surveyId = uuidv4();

        let survey = JSON.parse(localStorage.getItem('survey'));

        dispatch({ type: CLEAR_DOORS });

        try {
            dispatch({ type: CREATE_SURVEY_REQUEST });

            const { data } = await axios.post(
                'https://firedoor.napfis.co.uk/app_handler.php',
                fd,
                {
                    headers: {
                        'WG-Method': 'SAVE_CLIENTDATA',
                        'WG-Key': Cookie.get('accessToken'),
                    },
                }
            );

            console.log(data);

            dispatch({
                type: CREATE_SURVEY_SUCCESS,
                payload: {
                    id: surveyId,
                    userId,
                    recordId: data?.record_id,
                    doors: {},
                    surveyKey: data?.survey_key,
                    includeCosts,
                },
            });

            const newSurveyObject = {
                id: surveyId,
                userId,
                recordId: data?.record_id,
                doors: {},
                type: 'online',
                surveyKey: data?.survey_key,
                includeCosts,
            };

            if (survey) {
                survey[surveyId] = newSurveyObject;

                localStorage.setItem('survey', JSON.stringify(survey));
            } else {
                localStorage.setItem(
                    'survey',
                    JSON.stringify({ [surveyId]: newSurveyObject })
                );
            }

            let clientData = JSON.parse(localStorage.getItem('clientData'));

            if (clientData) {
                clientData[surveyId] = data?.fields;
                localStorage.setItem('clientData', JSON.stringify(clientData));
            } else {
                localStorage.setItem(
                    'clientData',
                    JSON.stringify({ [surveyId]: data?.fields })
                );
            }
        } catch (error) {
            const data = { id: surveyId, userId, doors: {}, type: 'offline' };

            dispatch({ type: CREATE_SURVEY_FAILURE, payload: data });

            if (survey) {
                survey[surveyId] = data;
                localStorage.setItem('survey', JSON.stringify(survey));
            } else {
                localStorage.setItem(
                    'survey',
                    JSON.stringify({ [surveyId]: data })
                );
            }

            let clientData = JSON.parse(localStorage.getItem('clientData'));

            if (clientData) {
                clientData[surveyId] = formPayload;
                localStorage.setItem('clientData', JSON.stringify(clientData));
            } else {
                localStorage.setItem(
                    'clientData',
                    JSON.stringify({ [surveyId]: formPayload })
                );
            }
        }

        localStorage.setItem('activeSurvey', surveyId);
    };

export const updateSurvey =
    (
        clientName,
        clientTelephone,
        clientEmail,
        surveyAddress,
        comments,
        includeCosts,
        recordId
    ) =>
    async (dispatch) => {
        const formPayload = {
            inspection_client_name: clientName,
            inspection_client_telephone: clientTelephone,
            inspection_client_email: clientEmail,
            inspection_survey_address: surveyAddress,
            inspection_comments: comments,
            inspection_include_costs: includeCosts,
        };

        const fd = new FormData();

        fd.append('inspection_client_name', clientName);
        fd.append('inspection_client_telephone', clientTelephone);
        fd.append('inspection_client_email', clientEmail);
        fd.append('inspection_survey_address', surveyAddress);
        fd.append('inspection_include_costs', includeCosts);

        fd.append('inspection_comments', comments);

        const surveyId = localStorage.getItem('activeSurvey');

        try {
            dispatch({ type: UPDATE_SURVEY_REQUEST });

            if (!recordId) throw new Error('No record ID');

            const { data } = await axios.post(
                'https://firedoor.napfis.co.uk/app_handler.php',
                fd,
                {
                    headers: {
                        'WG-Method': 'SAVE_CLIENTDATA',
                        'WG-Key': Cookie.get('accessToken'),
                        'WG-RecordId': recordId,
                    },
                }
            );

            dispatch({
                type: UPDATE_SURVEY_SUCCESS,
                payload: {
                    recordId: data?.record_id,
                    surveyKey: data?.survey_key,
                },
            });
        } catch (error) {
            dispatch({ type: UPDATE_SURVEY_FAILURE });
        }

        let clientData = JSON.parse(localStorage.getItem('clientData'));

        if (clientData) {
            clientData[surveyId] = formPayload;
            localStorage.setItem('clientData', JSON.stringify(clientData));
        } else {
            localStorage.setItem(
                'clientData',
                JSON.stringify({ [surveyId]: formPayload })
            );
        }
    };

export const loadDoor = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOAD_DOOR_REQUEST });

        const { data } = await axios.get(
            'https://firedoor.napfis.co.uk/app_handler.php',
            {
                headers: {
                    'WG-Method': 'GET_DOOR_TEMPLATE',
                    'Wg-EntryId': id,
                    'WG-Key': Cookie.get('accessToken'),
                },
            }
        );

        dispatch({ type: LOAD_DOOR_SUCCESS, payload: data });
    } catch (error) {
        const dependencies = JSON.parse(localStorage.getItem('dependencies'));

        dispatch({
            type: LOAD_DOOR_FAILURE,
            payload: dependencies?.form_templates?.door,
        });
    }
};

export const removeAllDoors = (recordId) => async (dispatch) => {
    try {
        dispatch({ type: REMOVE_ALL_DOORS_REQUEST });

        await axios.get('https://firedoor.napfis.co.uk/app_handler.php', {
            headers: {
                'WG-Method': 'REMOVE_ALL_DOORS',
                'Wg-RecordId': recordId,
                'WG-Key': Cookie.get('accessToken'),
            },
        });

        dispatch({ type: REMOVE_ALL_DOORS_SUCCESS });
    } catch (error) {
        dispatch({ type: REMOVE_ALL_DOORS_FAILURE, payload: error.message });
    }
};

export const clearDoorProceed = () => async (dispatch) => {
    dispatch({ type: CLEAR_DOOR_PROCEED });
};

export const assignPinsToDoors = (pins) => (dispatch) => {
    try {
        dispatch({ type: ASSIGN_PINS_REQUEST });

        dispatch({ type: ASSIGN_PINS_SUCCESS, payload: pins });
    } catch (error) {
        dispatch({
            type: ASSIGN_PINS_FAILURE,
            payload: error?.response.data.errors,
        });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    const activeSurvey = localStorage.getItem('activeSurvey');

    if (survey[activeSurvey]) {
        if (survey[activeSurvey]?.doors) {
            let index = 0;

            for (const [key, value] of Object.entries(
                survey[activeSurvey]?.doors
            )) {
                value.reference = pins[index]?.reference;
                value.pinId = pins[index]?.id;
                index++;
            }
        }
    }

    localStorage.setItem('survey', JSON.stringify(survey));
};

export const setActivePlan = (id) => (dispatch) => {
    try {
        dispatch({ type: SET_ACTIVE_PLAN_REQUEST });
        dispatch({ type: SET_ACTIVE_PLAN_SUCCESS, payload: id });

        const activeSurvey = localStorage.getItem('activeSurvey');
        let activePlan = JSON.parse(localStorage.getItem('activePlan'));

        if (activePlan) {
            if (activePlan[activeSurvey]) {
                activePlan[activeSurvey] = id;
            } else {
                const tmpActivePlan = {
                    [activeSurvey]: id,
                };

                activePlan = Object.assign(activePlan, tmpActivePlan);

                // activePlan = Object.assign(activePlan, { [activeSurvey]: id });
            }

            localStorage.setItem('activePlan', JSON.stringify(activePlan));
        } else {
            localStorage.setItem(
                'activePlan',
                JSON.stringify({ [activeSurvey]: id })
            );
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: SET_ACTIVE_PLAN_FAILURE });
    }
};

export const saveDoorRef = (id, ref) => (dispatch) => {
    try {
        dispatch({ type: SAVE_DOOR_REF_REQUEST });
        dispatch({ type: SAVE_DOOR_REF_SUCCESS, payload: { id, ref } });
    } catch (error) {
        dispatch({ type: SAVE_DOOR_REF_FAILURE });
    }

    let survey = JSON.parse(localStorage.getItem('survey'));
    const activeSurvey = localStorage.getItem('activeSurvey');

    if (survey[activeSurvey]) {
        console.log(survey[activeSurvey]);
        if (survey[activeSurvey]?.doors) {
            if (survey[activeSurvey]?.doors[id]) {
                survey[activeSurvey].doors[id].reference = ref;

                if (survey[activeSurvey].doors[id]?.data) {
                    if (
                        survey[activeSurvey].doors[id]?.data[
                            'door-reference_5f7c9de1088a0_formid_436'
                        ]
                    ) {
                        survey[activeSurvey].doors[id].data[
                            'door-reference_5f7c9de1088a0_formid_436'
                        ].value = ref;
                    }
                }
            }
        }
    }

    localStorage.setItem('survey', JSON.stringify(survey));
};

export const goToPDFSelector = () => (dispatch) => {
    dispatch({ type: SET_PDF_SELECTOR_TRUE });
};
