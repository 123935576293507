import axios from 'axios';
import Cookie from 'js-cookie';
import Localbase from 'localbase';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import {
    ADD_DOOR_REQUEST,
    ADD_DOOR_SUCCESS,
    SAVE_DOOR_PHOTO_REQUEST,
    SAVE_DOOR_PHOTO_SUCCESS,
    SAVE_DOOR_OTHER_REQUEST,
    SAVE_DOOR_OTHER_SUCCESS,
    REMOVE_DOOR_PHOTO_REQUEST,
    REMOVE_DOOR_PHOTO_SUCCESS,
    SAVE_DOOR_REQUEST,
    SAVE_DOOR_SUCCESS,
    REMOVE_DOOR_REQUEST,
    REMOVE_DOOR_SUCCESS,
    RESTORE_DOOR_REQUEST,
    RESTORE_DOOR_SUCCESS,
    SAVE_DOOR_REF_REQUEST,
    SAVE_DOOR_REF_SUCCESS,
    SAVE_DOOR_TYPE_REQUEST,
    SAVE_DOOR_TYPE_SUCCESS,
    SAVE_SECTION_REQUEST, 
    SAVE_SECTION_SUCCESS,
    SAVE_TO_LOCALSTORAGE_SUCCESS,
    SAVE_TO_LOCALSTORAGE_REQUEST,
    SAVE_FAILED_OPTION_REQUEST,
    SAVE_FAILED_OPTION_SUCCESS,
    SAVE_FAILED_OTHER_REQUEST,
    SAVE_FAILED_OTHER_SUCCESS
} from '../types/doorTypes';

export const addDoor = (parentId = null, type = null, ref = "") => async (dispatch, getState) => {
    const id = uuidv4();

    const state = getState();
    const { app: { sections }, door: { doors }, survey: { id: surveyId, recordId: surveyRecordId } } = state;

    dispatch({ type: ADD_DOOR_REQUEST });
    
    let data = { 
        id,
        recordId: 0,
        ref: ref !== "" ? ref : id,
        leftId: parentId,
        type,
        answers: _.cloneDeep(sections),
        other: {
            text: '',
            cost: 0.00
        },
        deleted: false
    };

    try {
        const { data: result } = await axios.post('https://firedoor.napfis.co.uk/app_handler.php', data, { headers: { 'WG-Method': 'ADD_DOOR', 'Wg-Surveyid': surveyRecordId, 'WG-Key': Cookie.get('accessToken') }});

        if (result) {
            data.recordId = result.record_id;
        }

    } catch (error) {

    }

    dispatch({ type: ADD_DOOR_SUCCESS, payload: data });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
};

export const saveDoor = (doorId) => async (dispatch, getState) => {
    const id = uuidv4();

    const state = getState();
    const { door: { doors }, survey: { id: surveyId, recordId: surveyRecordId } } = state;

    dispatch({ type: SAVE_DOOR_REQUEST });
    
    let data = doors[doorId] || [];

    try {
        const { data: result } = await axios.post('https://firedoor.napfis.co.uk/app_handler.php', data, { headers: { 'WG-Method': 'SAVE_DOOR_V2', 'Wg-Surveyid': surveyRecordId, 'Wg-Doorid': doorId, 'WG-Key': Cookie.get('accessToken') }});

        if (result && data.recordId === 0) {
            data.recordId = result.record_id;
        }

    } catch (error) {

    }

    dispatch({ type: SAVE_DOOR_SUCCESS, payload: data });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [surveyId]: { ...doors, [id]: data } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
};

export const saveSection = (doorId, sectionId, answer) => async (dispatch, getState) => {
    dispatch({ type: SAVE_SECTION_REQUEST });

    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    let answeredSection = currentDoor && currentDoor.answers.find((answer) => answer.id === sectionId);
    answeredSection.answer = answer;

    const data = { id: doorId, answers: [...currentDoor.answers.filter((answer) => answer.id !== sectionId), answeredSection] };

    try {
        await axios.post('https://firedoor.napfis.co.uk/app_handler.php', answeredSection, { headers: { 'WG-Method': 'SAVE_DOOR_SECTION', 'Wg-Doorid': currentDoor.recordId, 'Wg-Sectionid': sectionId, 'WG-Key': Cookie.get('accessToken') }});

    } catch (error) {

    }

    dispatch({ type: SAVE_SECTION_SUCCESS, payload: data });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], ...data } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveFailedOption = (doorId, sectionId, option) => async (dispatch, getState) => {
    dispatch({ type: SAVE_FAILED_OPTION_REQUEST });

    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    let answeredSection = currentDoor && currentDoor.answers.find((answer) => answer.id === sectionId);
    answeredSection.failedOption = option;

    const data = { id: doorId, answers: [...currentDoor.answers.filter((answer) => answer.id !== sectionId), answeredSection] };

    try {
        await axios.post('https://firedoor.napfis.co.uk/app_handler.php', answeredSection, { headers: { 'WG-Method': 'SAVE_FAILED_OPTION', 'Wg-Doorid': currentDoor.recordId, 'Wg-Sectionid': sectionId, 'WG-Key': Cookie.get('accessToken') }});

    } catch (error) {

    }

    dispatch({ type: SAVE_FAILED_OPTION_SUCCESS, payload: data });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveFailedOther = (doorId, sectionId, other) => async (dispatch, getState) => {
    dispatch({ type: SAVE_FAILED_OTHER_REQUEST });

    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    let answeredSection = currentDoor && currentDoor.answers.find((answer) => answer.id === sectionId);
    answeredSection.failedOther = other;

    const data = { id: doorId, answers: [...currentDoor.answers.filter((answer) => answer.id !== sectionId), answeredSection] };

    try {
        await axios.post('https://firedoor.napfis.co.uk/app_handler.php', answeredSection, { headers: { 'WG-Method': 'SAVE_FAILED_OTHER', 'Wg-Doorid': currentDoor.recordId, 'Wg-Sectionid': sectionId, 'WG-Key': Cookie.get('accessToken') }});

    } catch (error) {

    }

    dispatch({ type: SAVE_FAILED_OTHER_SUCCESS, payload: data });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveRef = (doorId, ref) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: SAVE_DOOR_REF_REQUEST });

    if (currentDoor?.recordId) {
        try {
            await axios.post('https://firedoor.napfis.co.uk/app_handler.php', { ref: ref }, { headers: { 'WG-Method': 'SAVE_DOOR_REF', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    dispatch({ type: SAVE_DOOR_REF_SUCCESS, payload: { id: doorId, ref } });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveType = (doorId, type) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: SAVE_DOOR_TYPE_REQUEST });
    
    if (currentDoor?.recordId) {
        try {
            await axios.post('https://firedoor.napfis.co.uk/app_handler.php', { type: type }, { headers: { 'WG-Method': 'SAVE_DOOR_TYPE', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    dispatch({ type: SAVE_DOOR_TYPE_SUCCESS, payload: { id: doorId, type } });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], type } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveOther = (doorId, otherText, otherCost) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: SAVE_DOOR_OTHER_REQUEST });
    
    if (currentDoor?.recordId) {
        try {
            await axios.post('https://firedoor.napfis.co.uk/app_handler.php', { text: otherText, cost: otherCost }, { headers: { 'WG-Method': 'SAVE_DOOR_OTHER', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }
    
    const other = {
        text: otherText,
        cost: otherCost
    };

    dispatch({ type: SAVE_DOOR_OTHER_SUCCESS, payload: { id: doorId, other } });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], other } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const savePhoto = (doorId, formData) => async (dispatch) => {
    const db = new Localbase('napfis-webapp');

    dispatch({ type: SAVE_DOOR_PHOTO_REQUEST });
    
    try {
        await axios.post('https://firedoor.napfis.co.uk/app_handler.php', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'WG-Method': 'SAVE_DOOR_PHOTO',
                'Wg-Doorid': doorId,
                'WG-Key': Cookie.get('accessToken')
            }
        });

        await db.collection('door-photos').doc({ id: doorId }).update({
            uploaded: true,
        });
    } catch (error) {

    }
    
    dispatch({ type: SAVE_DOOR_PHOTO_SUCCESS });
}

export const removePhoto = (doorId, fieldId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors } } = state;

    const recordId = doors[doorId]?.recordId;

    dispatch({ type: REMOVE_DOOR_PHOTO_REQUEST });
    
    if (recordId) {
        try {
            await axios.delete('https://firedoor.napfis.co.uk/app_handler.php', {
                headers: {
                    'WG-Method': 'REMOVE_DOOR_PHOTO',
                    'Wg-Doorid': recordId,
                    'Wg-Fieldid': fieldId,
                    'WG-Key': Cookie.get('accessToken')
                }
            });
        } catch (error) {
    
        }
    }

    dispatch({ type: REMOVE_DOOR_PHOTO_SUCCESS });
}

export const removeDoor = (doorId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: REMOVE_DOOR_REQUEST });

    if (currentDoor?.recordId) {
        try {
            await axios.post('https://firedoor.napfis.co.uk/app_handler.php', {}, { headers: { 'WG-Method': 'REMOVE_DOOR', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    dispatch({ type: REMOVE_DOOR_SUCCESS, payload: doorId });

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const restoreDoor = (doorId) => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const currentDoor = doors[doorId];

    dispatch({ type: RESTORE_DOOR_REQUEST });

    if (currentDoor?.recordId) {
        try {
            await axios.post('https://firedoor.napfis.co.uk/app_handler.php', {}, { headers: { 'WG-Method': 'RESTORE_DOOR', 'Wg-Doorid': currentDoor?.recordId, 'WG-Key': Cookie.get('accessToken') }});
    
        } catch (error) {
    
        }
    }

    dispatch({ type: RESTORE_DOOR_SUCCESS, payload: doorId });

    // const savedDoors = JSON.parse(localStorage.getItem('doors'));
    // const tmpDoors = { ...savedDoors, [id]: { ...doors, [doorId]: { ...doors[doorId], deleted: false } } };
    // localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_REQUEST });
}

export const saveDoorToLocalStorage = () => async (dispatch, getState) => {
    const state = getState();
    const { door: { doors }, survey: { id } } = state;

    const savedDoors = JSON.parse(localStorage.getItem('doors'));
    const tmpDoors = { ...savedDoors, [id]: { ...doors } };
    localStorage.setItem('doors', JSON.stringify(tmpDoors));

    dispatch({ type: SAVE_TO_LOCALSTORAGE_SUCCESS });
}

// export const saveDoor = (doorId) => async dispatch => {
//     const db = new Localbase('saved-uploads');

//     try {
//         dispatch({ type: SAVE_DOOR_REQUEST });

//         const fd = new FormData();

//         for (const [key, value] of Object.entries(formData)) {
//             if (value.type === 'file') {
//                 if (typeof value.value === 'object') {
//                     fd.append(key, value.value, value.value.name);
                    
//                     db.collection(`door-images-${activeSurvey}`).add({ key, file: value.value }, `${id}_${key}`);
//                 } else {
//                     fd.append(key, value.value);
//                 }
//             } else {
//                 fd.append(key, value.value);

//                 console.log(value.value);

//                 if (_.includes(requiredFields, key) && value.value !== "") {
//                     completedFields++;
//                 }
//             }
//         }

//         if (doorCosts) {
//             if (doorCosts[activeSurvey]) {
//                 if (doorCosts[activeSurvey][id]) {
//                     doorCosts[activeSurvey][id] = costs;
//                     localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
//                 } else {
//                     doorCosts[activeSurvey] = { ...doorCosts[activeSurvey], [id]: costs };
//                     localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
//                 }
//             } else {
//                 doorCosts = { [activeSurvey]: { [id]: costs }};
//                 localStorage.setItem('doorCosts', JSON.stringify(doorCosts));
//             }
//         } else {
//             const newDoorCosts = { [id]: costs };

//             localStorage.setItem('doorCosts', JSON.stringify({ [activeSurvey]: newDoorCosts }));
//         }

//         let doorScores = JSON.parse(localStorage.getItem('doorScores'));
        
//         if (doorScores) {
//             if (doorScores[activeSurvey]) {
//                 if (doorScores[activeSurvey][id]) {
//                     doorScores[activeSurvey][id] = scores;
//                     localStorage.setItem('doorScores', JSON.stringify(doorScores));
//                 } else {
//                     doorScores[activeSurvey] = { ...doorScores[activeSurvey], [id]: scores };
//                     localStorage.setItem('doorScores', JSON.stringify(doorScores));
//                 }
//             } else {
//                 doorScores = { [activeSurvey]: { [id]: scores }};
//                 localStorage.setItem('doorScores', JSON.stringify(doorScores));
//             }
//         } else {
//             const newDoorScores = { [id]: scores };
//             localStorage.setItem('doorScores', JSON.stringify({ [activeSurvey]: newDoorScores }));
//         }

//         const survey = JSON.parse(localStorage.getItem('survey'));

//         let doors = {};
//         if (survey[activeSurvey]) {
//             doors = survey[activeSurvey]?.doors;
//         }
    
//         const { data } = await axios.post('https://firedoor.napfis.co.uk/app_handler.php', fd, {
//             headers: {
//                 'Access-Control-Allow-Origin': '*',
//                 'WG-EntryId': doors[id]?.doorId || id,
//                 'WG-RecordId': surveyId,
//                 'Wg-Method': 'SAVE_DOOR',
//                 'Wg-Key': Cookie.get('accessToken')
//             }
//         });

//         doorId = data?.form_entry_id;
//         surveyId = data?.survey_id;

//         if (data?.fields) {
//             const db = new Localbase('saved-uploads');
            
//             for (const [key, value] of Object.entries(formData)) {
//                 if (data?.fields[key]) {
//                     savedFormData[key] = { type: value.type, value: data?.fields[key] };

//                     try {

//                         const document = await db.collection(`door-images-${activeSurvey}`).doc(`${id}_${key}`).get();

//                         if (document) {
//                             await db.collection(`door-images-${activeSurvey}`).doc(`${id}_${key}`).delete();
//                         }

//                     } catch (error) {
//                         console.error(error);
//                     }
//                 }
//             }
//         }

//         doorToSave = { id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };

//         dispatch({ type: SAVE_DOOR_SUCCESS, payload: { door: doorToSave, recordId: surveyId }});

//     } catch (error) {
//         doorToSave = { id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };

//         dispatch({ type: SAVE_DOOR_FAILURE, payload: { door: doorToSave, recordId: surveyId }});
//     }

//     let survey = _.cloneDeep(JSON.parse(localStorage.getItem('survey')));
    
//     if (survey[activeSurvey]) {

//         survey[activeSurvey].recordId = surveyId;

//         localStorage.setItem('survey', JSON.stringify(survey));
        

//         survey[activeSurvey].recordId = surveyId;
    
//         if (survey[activeSurvey].doors[id]) {
//             survey[activeSurvey].doors[id] = { ...survey[activeSurvey].doors[id], id, doorId, data: savedFormData, total, reference: savedFormData['door-reference_5f7c9de1088a0_formid_436']['value'], planRef, completedFields, scores };
//         }
    
//         localStorage.setItem('survey', JSON.stringify(survey));
//     }

// };